import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Shop } from '@/store/modules/data_interfaces/ShopUserData';
import LocalStorageService from '@/core/services/LocalStorageService';
import ApiService from '@/core/services/ApiService';

@Module
export default class ShopPreferencesModuleLocally extends VuexModule {
    errors = {};
    Shop_prefrences: Shop | undefined;
    shopPreferences;

    get getShopPreferences() {
        return this.shopPreferences;
    }
    get getUserShopPrefErrors() {
        return this.errors;
    }
    get getShopPreferencesLocally() {
        //get Shop Prefernces
        return this.Shop_prefrences;
    }
    get getPrefErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_SHOP_PREFERENCE_LOCALY](shop_preferences: Shop) {
        this.errors = {};
        this.Shop_prefrences = shop_preferences;
    }

    @Action
    [Actions.GET_SHOP_PREFERENCE_LOCALY]() {
        let shop_preferences: any = LocalStorageService.getShopPreferences();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        shop_preferences = JSON.parse(shop_preferences);
        this.context.commit(
            Mutations.SET_SHOP_PREFERENCE_LOCALY,
            shop_preferences
        );
    }
    @Action
    [Actions.UPDATE_SHOP_PREFERENCE_LOCALY](shop) {
        LocalStorageService.saveShopPreferences(shop);
        this.context.commit(Mutations.SET_SHOP_PREFERENCE_LOCALY, shop);
    }

    @Action
    [Actions.UPDATE_SHOP_PREFERENCE](data) {
        console.log('inside api calling', data);
        return ApiService.post('/shop/preference/auth/update_preferences', data)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch((err) => {
                if (!err.response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        err.response.data.error ||
                            err.response.data.message ||
                            'Error Occured',
                    ]);
                }
            });
    }
    @Action
    [Actions.ADD_SPECIAL_CHECKOUT_CHARGES](data) {
        console.log('inside api calling', data);
        return ApiService.post(
            '/shop/preference/auth/add_special_checkout_charges',
            data
        )
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch((err) => {
                if (!err.response) {
                    console.log('inside api calling err1', err);
                    this.context.commit(Mutations.SET_ERROR, [
                        'Network Error!',
                    ]);
                } else {
                    console.log(
                        'inside api calling err.response',
                        err.response.data.message
                    );
                    this.context.commit(Mutations.SET_ERROR, [
                        err.response.data.error ||
                            err.response.data.message ||
                            'Error Occured',
                    ]);
                }
            });
    }
    @Mutation
    [Mutations.SET_SHOP_PREFERENCE](data) {
        this.errors = {};
        this.shopPreferences = data;
    }
    @Mutation
    [Mutations.SET_VERIFY_M4U_URL]() {
        this.errors = {};
    }

    @Action
    [Actions.GET_SHOP_PREFERENCE](data) {
        return ApiService.post(
            '/shop/preference/auth/get_shop_preferences',
            data
        )
            .then(({ data }) => {
                this.context.commit(Mutations.SET_SHOP_PREFERENCE, data);
            })
            .catch((err) => {
                if (!err.response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        err.response.data.error ||
                            err.response.data.message ||
                            'Error Occured',
                    ]);
                }
            });
    }
    @Action
    [Actions.VERIFY_M4U_URL](data) {
        return ApiService.post('/shop/preference/auth/authenticate_url', data)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_VERIFY_M4U_URL, data);
            })
            .catch((err) => {
                if (!err.response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        err.response.data.error ||
                            err.response.data.message ||
                            'Error Occured',
                    ]);
                }
            });
    }
}
