import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import ApiService from '@/core/services/ApiService';

@Module
export default class CategoryModule extends VuexModule {
    errors = {};
    category: object = {};
    categories: Array<any> = [];

    get getCategoryErrors() {
        return this.errors;
    }

    get getShopsCategories() {
        return this.categories;
    }

    get getCategory() {
        return this.category;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_SHOP_CATEGORIES](payload) {
        this.errors = {};
        this.categories = payload.categories;
    }

    @Mutation
    [Mutations.SET_A_CATEGORY](payload) {
        this.errors = {};
        this.category = payload.category;
    }

    // Get Shop Categories
    @Action
    [Actions.GET_SHOP_CATEGORIES](payload) {
        ApiService.setHeader();

        return ApiService.post('/category/all_categories', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_SHOP_CATEGORIES, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }

    // Get Shop Categories
    @Action
    [Actions.GET_A_CATEGORY](payload) {
        ApiService.setHeader();

        return ApiService.post(`/category/get_category/${payload.id}`, payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_A_CATEGORY, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }

    // Add new Category
    @Action
    [Actions.ADD_SHOP_CATEGORY](payload) {
        ApiService.setHeader();

        return ApiService.post('/category/add_category', payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }

    // Edit a Category
    @Action
    [Actions.EDIT_SHOP_CATEGORY](payload) {
        ApiService.setHeader();

        return ApiService.post('/category/edit_category', payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }

    // Update sort_order of categories
    @Action
    [Actions.UPDATE_CATEGORY_SORT_ORDER](payload) {
        ApiService.setHeader();

        return ApiService.post('/category/update_sort_order', payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }

    // Delete a Category
    @Action
    [Actions.DELETE_SHOP_CATEGORY](payload) {
        ApiService.setHeader();

        return ApiService.post('/category/delete_category', payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    if (response.data.message && response.data.menu_ids) {
                        this.context.commit(Mutations.SET_ERROR, [
                            {
                                message: response.data.message,
                                menu_ids: response.data.menu_ids,
                            },
                        ]);
                    } else {
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.error ||
                                response.data.errors ||
                                response.data.message ||
                                response.data.menu_ids,
                        ]);
                    }
                }
            });
    }
}
