import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import ApiService from '@/core/services/ApiService';

@Module
export default class TagModule extends VuexModule {
    errors = {};
    // TODO: Sample array, will be empty when tags will be present in mongodb
    tags: Array<any> = [
        {
            _id: 1,
            name: 'A',
        },
        {
            _id: 2,
            name: 'B',
        },
        {
            _id: 3,
            name: 'C',
        },
    ];

    get getAllTags() {
        return this.tags;
    }

    get getTagsErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_TAGS](payload) {
        this.errors = {};
        this.tags = payload;
    }

    // TODO: Uncomment when tags route available

    // Get all tags of a shop
    @Action
    [Actions.GET_TAGS]() {
        ApiService.setHeader();

        return ApiService.get('/get_all_tags')
            .then(({ data }) => {
                this.context.commit(Mutations.SET_TAGS, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }

    get shopTags() {
        return this.tags;
    }
}
