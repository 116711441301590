import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import ApiService from '@/core/services/ApiService';

@Module
export default class DashboardModule extends VuexModule {
    // errors = {};
    // shops: Array<any> = [];
    // get getShopErrors() {
    //   return this.errors;
    // }
    // get getUsersShops() {
    //   return this.shops;
    // }
    // @Mutation
    // [Mutations.SET_ERROR](error) {
    //   this.errors = { ...error };
    // }
    // @Mutation
    // [Mutations.SET_USER_SHOPS](payload) {
    //   this.errors = {};
    //   this.shops = payload.shops;
    // }
    // // Get Users shops
    // @Action
    // [Actions.GET_USER_SHOPS]() {
    //   ApiService.setHeader();
    //   return ApiService.get("/shop/auth/get_shops_of_a_user")
    //     .then(({ data }) => {
    //       this.context.commit(Mutations.SET_USER_SHOPS, data);
    //     })
    //     .catch(({ response }) => {
    //       if (!response) {
    //         this.context.commit(Mutations.SET_ERROR, ["Network Error"]);
    //       } else {
    //         this.context.commit(Mutations.SET_ERROR, [response.data.error]);
    //       }
    //     });
    // }
}
