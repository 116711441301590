import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import ApiService from '@/core/services/ApiService';

@Module
export default class MenuModule extends VuexModule {
    errors = {};
    menu: object = {};
    menus: Array<any> = [];
    categories: Array<any> = [];

    get getMenuErrors() {
        return this.errors;
    }

    get getMenu() {
        return this.menus;
    }

    get getmenu() {
        return this.menu;
    }

    get getCategoryWithMenus() {
        return this.categories;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        if (error && error[0] && Array.isArray(error[0])) {
            // these are express validator's errors and should be processed based on assumptions that it has the
            // following structure
            // {
            //    msg : '',
            //    param: '',
            //    location : ''
            // }

            const allErrors = error[0].reduce((accumulator, { msg }) => {
                return accumulator + msg + '\n';
            }, '');
            this.errors = { message: allErrors };
            return;
        }
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_MENUS](payload) {
        this.errors = {};
        this.menus = payload.menus;
    }

    @Mutation
    [Mutations.SET_MENU](payload) {
        this.errors = {};
        this.menu = payload.menu;
    }

    @Mutation
    [Mutations.SET_CATEGORIES_WITH_MENUS](payload) {
        this.errors = {};
        this.categories = payload.categories;
    }

    // Get Menus of a shop
    @Action
    [Actions.GET_MENUS](payload) {
        ApiService.setHeader();

        return ApiService.post('/menu/all_menus', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_MENUS, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }

    // Get a menu of a shop
    @Action
    [Actions.GET_A_MENU](payload) {
        ApiService.setHeader();

        return ApiService.post(`/menu/get_menu/${payload.id}`, payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_MENU, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }

    // Get categories with menus of a shop
    @Action
    [Actions.GET_CATEGORIES_WITH_MENUS](payload) {
        ApiService.setHeader();

        return ApiService.post('/menu/get_categories_with_menus', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_CATEGORIES_WITH_MENUS, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }

    // Add new Menu
    @Action
    [Actions.ADD_MENU](payload) {
        ApiService.setHeader();

        return ApiService.post('/menu/add_menu', payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }

    // Edit a Menu
    @Action
    [Actions.EDIT_MENU](payload) {
        ApiService.setHeader();

        return ApiService.post('/menu/edit_menu', payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }

    // Update sort_order of menus
    @Action
    [Actions.UPDATE_MENU_SORT_ORDER](payload) {
        ApiService.setHeader();

        return ApiService.post('/menu/update_sort_order', payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }

    // Delete a Menu
    @Action
    [Actions.DELETE_MENU](payload) {
        ApiService.setHeader();

        return ApiService.post('/menu/delete_menu', payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }
}
