import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import AuthModule from '@/store/modules/AuthModule';
import BodyModule from '@/store/modules/BodyModule';
import ConfigModule from '@/store/modules/ConfigModule';
import ThemeModeModule from '@/store/modules/ThemeModeModule';
import DashboardModule from '@/store/modules/DashboardModule';
import CategoryModule from '@/store/modules/menus/CategoryModule';
import MenuModule from '@/store/modules/menus/MenuModule';
import ComboModule from '@/store/modules/menus/ComboModule';
import ShopUsersModule from '@/store/modules/shop_user_module/ShopUsersModule';
import ShopPreferencesModuleLocally from '@/store/modules/shop_preferences_locally/ShopPreferencesModule';
import TagModule from '@/store/modules/menus/TagModule';
import PossibleAllergiesModule from '@/store/modules/menus/PossibleAllergiesModule';
import UploadFileModule from '@/store/modules/UploadFileModule';
import OffersModule from '@/store/modules/OffersModule';
import OrdersModule from '@/store/modules/OrdersModule';
import InvoiceModule from '@/store/modules/InvoiceModule';

config.rawError = true;

const store = createStore({
    modules: {
        AuthModule,
        BodyModule,
        ConfigModule,
        ThemeModeModule,
        DashboardModule,
        CategoryModule,
        MenuModule,
        ComboModule,
        ShopUsersModule,
        TagModule,
        PossibleAllergiesModule,
        ShopPreferencesModuleLocally,
        UploadFileModule,
        OffersModule,
        OrdersModule,
        InvoiceModule,
    },
});

export default store;
