import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { order_status } from '@/utils/mixins/config';
import {
    RefundPostDataTypes,
    CancelRefundDataTypes,
} from '@/utils/mixins/types';

@Module
export default class OrdersModule extends VuexModule {
    errors = {};
    orders: Array<any> = [];
    all_orders_current_page = 0;
    all_orders_total_rows = 0;
    history_of_orders_current_page = 0;
    history_of_orders_total_rows = 0;
    history_of_orders: Array<Array<any>> = [];
    pending = 0;
    delivered = 0;
    pickup = 0;
    summary: any = {};

    analytics: any = {};

    fake_orders: any = {};
    refund_response: any = {};

    get getOrdersErrors() {
        return this.errors;
    }

    get getNewOrders() {
        return this.orders.filter(
            (item) => item.order_status === order_status.order_placed
        );
    }

    get getPendingOrders() {
        return this.orders.filter(
            (item) => item.order_status === order_status.order_accepted
        );
    }

    get getReadyOrders() {
        return this.orders.filter(
            (item) =>
                item.order_status === order_status.order_prepared ||
                item.order_status === order_status.order_out_for_delivery
        );
    }

    get getCompletedOrders() {
        return this.orders.filter(
            (item) =>
                item.order_status === order_status.order_delivered ||
                item.order_status === order_status.order_collected
        );
    }

    get getDeliveredOrders() {
        return this.orders.filter(
            (item) => item.order_status === order_status.order_delivered
        );
    }

    get getPickupOrders() {
        return this.orders.filter(
            (item) => item.order_status === order_status.order_collected
        );
    }

    get getRejectedOrders() {
        return this.orders.filter(
            (item) => item.order_status === order_status.order_rejected
        );
    }

    get getOrderSumamry() {
        return this.summary;
    }

    get getOrderAnalytics() {
        return this.analytics;
    }

    get getPlaceFakeOrders() {
        return this.fake_orders;
    }
    get getRefund() {
        return this.refund_response;
    }

    get getAllHistoryOfOrders() {
        if (this.history_of_orders_current_page > 0) {
            return this.history_of_orders[
                this.history_of_orders_current_page - 1
            ];
        } else {
            return [];
        }
    }

    get totalHistoryOfOrders() {
        return this.history_of_orders_total_rows;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }
    @Mutation
    [Mutations.SET_ALL_ORDERS](payload) {
        this.errors = {};
        this.orders = payload.orders;
    }
    @Mutation
    [Mutations.SET_ALL_ORDERS_PAGE](page) {
        this.errors = {};
        this.all_orders_current_page = page;
    }
    @Action
    [Actions.GET_ALL_ORDERS](object) {
        // This commit sets current page
        this.context.commit(Mutations.SET_ALL_ORDERS_PAGE, object.page);

        ApiService.setHeader();
        return ApiService.get(
            '/get_orders?limit=' +
                object.limit +
                '&page=' +
                object.page +
                '&shop_id=' +
                object.shop_id +
                '&status=' +
                object.status
        )
            .then(({ data }) => {
                data.page = object.page;
                this.context.commit(Mutations.SET_ALL_ORDERS, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }

    @Mutation
    [Mutations.SET_HISTORY_OF_ORDERS](payload) {
        this.errors = {};
        this.history_of_orders[payload.page - 1] = payload.orders;
        this.history_of_orders_total_rows = payload.total;
    }

    @Mutation
    [Mutations.SET_HISTORY_OF_ORDERS_PAGE](page) {
        this.errors = {};
        this.history_of_orders_current_page = page;
    }

    @Action
    [Actions.GET_HISTORY_OF_ORDERS](object) {
        // This commit sets current page
        this.context.commit(Mutations.SET_HISTORY_OF_ORDERS_PAGE, object.page);
        ApiService.setHeader();
        return ApiService.get(
            '/get_history_of_orders?limit=30&page=' +
                object.page +
                '&shop_id=' +
                object.shop_id
        )
            .then(({ data }) => {
                data.page = object.page;
                this.context.commit(Mutations.SET_HISTORY_OF_ORDERS, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }

    @Mutation
    [Mutations.SET_ORDER_STATUS]() {
        this.errors = {};
    }
    @Action
    [Actions.CHANGE_ORDER_STATUS](object) {
        ApiService.setHeader();
        return ApiService.get(
            '/update_order_status?id=' +
                object.id +
                '&status=' +
                object.status +
                '&shop_id=' +
                object.shop_id
        )
            .then(({ data }) => {
                this.context.commit(Mutations.SET_ORDER_STATUS);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }

    @Mutation
    [Mutations.SET_ORDER_SUMMARY](payload) {
        this.errors = {};
        payload.today && (this.summary.today = payload.today);
        payload.yesterday && (this.summary.yesterday = payload.yesterday);
        payload.last_seven_days &&
            (this.summary.last_seven_days = payload.last_seven_days);
        payload.last_thirty_days &&
            (this.summary.last_thirty_days = payload.last_thirty_days);
        payload.custom && (this.summary.custom = payload.custom);
    }
    @Action
    [Actions.GET_ORDER_SUMMARY](object) {
        ApiService.setHeader();
        let url = '';
        if (object.start_date && object.end_date) {
            url =
                '/get_orders_statistics?shop_id=' +
                object.shop_id +
                '&start_date=' +
                object.start_date +
                '&end_date=' +
                object.end_date;
        } else {
            url = '/get_orders_statistics?shop_id=' + object.shop_id;
        }
        return ApiService.get(url)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_ORDER_SUMMARY, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }

    @Mutation
    [Mutations.SET_ORDER_ANALYTICS](payload) {
        this.errors = {};
        this.analytics.total_orders = payload.total_orders;
        this.analytics.completed_orders = payload.completed_orders;
        this.analytics.month = payload.month;
        this.analytics.this_month_sale = payload.this_month_sale;
        this.analytics.percentage = payload.percentage;
        this.analytics.cash_orders = payload.cash_orders;
        this.analytics.card_orders = payload.card_orders;
        this.analytics.rejected_orders = payload.rejected_orders;
        this.analytics.rejected_orders_amount = payload.rejected_orders_amount;
        this.analytics.sales_data = payload.sales_data;
        this.analytics.top_categories_data = payload.top_categories_data;
        this.analytics.top_menus_data = payload.top_menus_data;
        this.analytics.last_7_days_total_sale = payload.last_7_days_total_sale;
        this.analytics.total_shop_goal = payload.total_shop_goal;
        this.analytics.combined_analytics = payload.combined_analytics;
    }

    @Mutation
    [Mutations.SET_PLACE_FAKE_ORDERS](payload) {
        this.errors = {};
        this.fake_orders = payload;
    }
    @Mutation
    [Mutations.SET_REMOVE_PLACE_FAKE_ORDERS](payload) {
        this.errors = {};
        this.fake_orders = payload;
    }
    @Mutation
    [Mutations.SET_REQUEST_REFUND](payload) {
        this.errors = {};
        this.refund_response = payload;
    }
    @Mutation
    [Mutations.SET_CANCEL_REFUND](payload) {
        this.errors = {};
        this.refund_response = payload;
    }
    @Action
    [Actions.GET_ORDER_ANALYTICS](object) {
        ApiService.setHeader();
        const post_object: any = {
            shop_ids: object.shop_ids,
        };
        return ApiService.post(
            '/get_orders_analytics?shop_id=' + object.shop_id,
            post_object
        )
            .then(({ data }) => {
                this.context.commit(Mutations.SET_ORDER_ANALYTICS, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }

    @Action
    [Actions.GET_PLACE_FAKE_ORDERS](object) {
        ApiService.setHeader();
        const post_object: any = {
            shop_id: object.shop_id,
            limit: object.limit.value,
            order_time: object.orderTime.value,
        };
        return ApiService.post('/fake_orders', post_object)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_PLACE_FAKE_ORDERS, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }

    @Action
    [Actions.GET_REMOVE_PLACE_FAKE_ORDERS](object) {
        ApiService.setHeader();
        const post_object: any = {
            shop_id: object.shop_id,
        };
        return ApiService.post('/remove_fake_orders', post_object)
            .then(({ data }) => {
                this.context.commit(
                    Mutations.SET_REMOVE_PLACE_FAKE_ORDERS,
                    data
                );
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }
    @Action
    [Actions.GET_REQUEST_REFUND](object: RefundPostDataTypes) {
        ApiService.setHeader();
        const refundPostObject: RefundPostDataTypes = {
            order_id: object.order_id,
            shop_id: object.shop_id,
            requested_by: object.requested_by,
            refund_amount: object.refund_amount,
            refund_type: object.refund_type,
            reason: object.reason,
        };
        return ApiService.post('/shop/refund/request_refund', refundPostObject)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_REQUEST_REFUND, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }
    @Action
    [Actions.GET_CANCEL_REFUND](object: CancelRefundDataTypes) {
        ApiService.setHeader();
        const cancelRefundObject: CancelRefundDataTypes = {
            order_id: object.order_id,
            shop_id: object.shop_id,
        };
        return ApiService.post('/shop/refund/cancel_refund', cancelRefundObject)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_CANCEL_REFUND, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [
                    response.data.error ||
                        response.data.errors ||
                        response.data.message,
                ]);
            });
    }
}
