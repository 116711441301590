enum Actions {
    // action types
    ADD_BODY_CLASSNAME = 'addBodyClassName',
    REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
    ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
    REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
    ADD_CLASSNAME = 'addClassName',
    VERIFY_AUTH = 'verifyAuth',
    LOGIN = 'login',
    LOGOUT = 'logout',
    REGISTER = 'register',
    UPDATE_USER = 'updateUser',
    FORGOT_PASSWORD = 'forgotPassword',
    RESET_PASSWORD = 'resetPassword',
    SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
    SET_THEME_MODE_ACTION = 'setThemeModeAction',
    UPLOAD_FILE = 'uploadFile',
    GET_USER_SHOPS = 'getUserShops',
    GET_SHOP_CATEGORIES = 'getShopCategories',
    ADD_SHOP_CATEGORY = 'addShopCategory',
    GET_SHOP_CATEGORY = 'getShopCategory',
    GET_A_CATEGORY = 'getACategory',
    EDIT_SHOP_CATEGORY = 'editShopCategory',
    DELETE_SHOP_CATEGORY = 'deleteShopCategory',
    ADD_MENU = 'addMenu',
    GET_MENUS = 'getMenus',
    GET_A_MENU = 'getAMenu',
    GET_CATEGORIES_WITH_MENUS = 'getCategoriesWithMenus',
    UPDATE_CATEGORY_SORT_ORDER = 'updateCategorySortOrder',
    UPDATE_MENU_SORT_ORDER = 'updateMenuSortOrder',
    EDIT_MENU = 'editMenu',
    DELETE_MENU = 'deleteMenu',
    ADD_COMBO = 'addCombo',
    GET_COMBOS = 'getCombos',
    EDIT_COMBO = 'editCombo',
    DELETE_COMBO = 'deleteCombo',
    GET_SHOP_USERS = 'getShopUsersNew',
    Add_SHOP_USERS = 'addShopUsersNew',
    DELETE_SHOP_USER = 'deleteShopUser',
    GET_TAGS = 'getTags',
    GET_POSSIBLE_ALLERGIES = 'getPossibleAllergies',
    GET_SHOP_PREFERENCE_LOCALY = 'getShopPreferenceLocally',
    UPDATE_SHOP_PREFERENCE_LOCALY = 'updateShopPreferenceLocally',
    UPDATE_SHOP_PREFERENCE = 'updateOpeningHours',
    VERIFY_M4U_URL = 'verifyM4uURL',
    GET_SHOP_PREFERENCE = 'getShopPreference',
    UPDATE_HOLIDAY_OPENING_HOURS = 'updateHolidayOpeningHours',
    UPDATE_OPENING_HOURS = 'updateOpeningHours',
    EDIT_SHOP_USER = 'editShopUser',
    EDIT_SHOP_USER_ROLE = 'editShopUserRole',
    SEND_RESET_PASSWORD_LINK = 'sendResetPasswordLink',
    UPDATE_USER_PASSWORD = 'updateUserPassword',
    CREATE_NEW_OFFER = 'createNewOffer',
    GET_ALL_OFFERS = '_getAllOffers',
    UPDATE_OFFER = '_updateNewOffer',
    DELETE_SHOP_OFFER = '_deleteShopOffer',
    GET_SPECIFIC_OFFER_ANALYTICS = 'getSpecifcOfferAnalytics',
    GET_ALL_OFFER_ANALYTICS = 'getAllOfferAnalytics',
    GET_OFFER_BY_ID = 'getOfferByID',
    GET_ALL_ORDERS = '_getAllOrders',
    GET_HISTORY_OF_ORDERS = '_getHistoryOfOrders',
    CHANGE_ORDER_STATUS = '_changeOrderStatus',

    GET_ORDER_SUMMARY = 'GET_ORDER_SUMMARY',
    GET_ORDER_ANALYTICS = 'GET_ORDER_ANALYTICS',
    GET_PLACE_FAKE_ORDERS = 'GET_PLACE_FAKE_ORDERS',
    GET_REQUEST_REFUND = 'GET_REQUEST_REFUND',
    GET_CANCEL_REFUND = 'GET_CANCEL_REFUND',
    GET_REMOVE_PLACE_FAKE_ORDERS = 'GET_REMOVE_PLACE_FAKE_ORDERS',

    //invoices
    CREATE_INVOICES = 'CREATE_INVOICES',
    GET_INVOICES = 'GET_INVOICES',
    DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE',

    // checkout_special_charges
    ADD_SPECIAL_CHECKOUT_CHARGES = 'ADD_SPECIAL_CHECKOUT_CHARGES',
}

enum Mutations {
    // mutation types
    SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
    PURGE_AUTH = 'logOut',
    SET_AUTH = 'setAuth',
    SET_USER = 'setUser',
    SET_PASSWORD = 'setPassword',
    SET_ERROR = 'setError',
    SET_MESSAGE = 'setMessage',
    SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
    SET_LAYOUT_CONFIG_PROPERTY = 'setLayoutConfigProperty',
    RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
    OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
    OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
    SET_THEME_MODE_MUTATION = 'setThemeModeMutation',
    SET_USER_SHOPS = 'setUserShops',
    SET_FILE = 'setFile',
    SET_SHOP_CATEGORIES = 'setShopCategories',
    SET_A_CATEGORY = 'setACategory',
    SET_MENUS = 'setMenus',
    SET_MENU = 'setMenu',
    SET_CATEGORIES_WITH_MENUS = 'setCategoriesWithMenus',
    SET_COMBOS = 'setCombos',
    SET_SHOP_USERS = 'setShopUsersNew',
    SET_TAGS = 'setTags',
    SET_POSSIBLE_ALLERGIES = 'setPossibleAllergies',
    SET_SHOP_PREFERENCE_LOCALY = 'setShopPreferenceLocally',
    SET_SHOP_PREFERENCE = 'setShopPreference',
    SET_ALL_OFFERS = 'setAllOffers',
    SET_OFFER = 'setOffer',
    UPDATE_OFFER = '_updateOffer',
    DELETE_OFFER = '_deleteOffer',
    SET_SPECIFIC_OFFER_ANALYTICS = 'setSpecificOfferAnalytics',
    SET_ALL_OFFER_ANALYTICS = 'setAllOfferAnalytics',
    SET_OFFER_BY_ID = 'setOfferByID',
    SET_ALL_ORDERS = '_setAllOrders',
    SET_ALL_ORDERS_PAGE = '_setAllOrdersPage',
    SET_HISTORY_OF_ORDERS_PAGE = '_setHistoryOfOrderPage',
    SET_HISTORY_OF_ORDERS = '_setHistoryOfOrders',
    SET_ORDER_STATUS = '_setOrderStatus',

    SET_ORDER_SUMMARY = 'SET_ORDER_SUMMARY',
    SET_ORDER_ANALYTICS = 'SET_ORDER_ANALYTICS',
    SET_PLACE_FAKE_ORDERS = 'SET_PLACE_FAKE_ORDERS',
    SET_REMOVE_PLACE_FAKE_ORDERS = 'SET_REMOVE_PLACE_FAKE_ORDERS',
    SET_REQUEST_REFUND = 'SET_REQUEST_REFUND',
    SET_CANCEL_REFUND = 'SET_CANCEL_REFUND',
    //invoices
    SET_INVOICES = 'SET_INVOICES',

    SET_VERIFY_M4U_URL = 'setVerifyM4uURL',
}

export { Actions, Mutations };
