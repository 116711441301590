import { Shop } from '@/store/modules/data_interfaces/ShopUserData';
const ID_SHOP = 'id_shop' as string;
const ID_SHOPs = 'id_shops' as string;
const USER_OBJECT = 'user_object' as string;
const SHOP_PREFERENCES = 'shop_preferences' as string;
/**
 * @description get shop id form localStorage
 */
export const getShopID = (): string | null => {
    return window.localStorage.getItem(ID_SHOP);
};

/**
 * @description save shop id into localStorage
 */
export const saveShopId = (id): void => {
    window.localStorage.setItem(ID_SHOP, id);
};

/**
 * @description remove shop id form localStorage
 */
export const destroyShopID = (): void => {
    window.localStorage.removeItem(ID_SHOP);
};
/**
 * @description remove shop id form localStorage
 */
export const destroyShopIDs = (): void => {
    window.localStorage.removeItem(ID_SHOPs);
};
/**
 * @description remove shop id form localStorage
 */
export const saveUserObject = (user): void => {
    window.localStorage.setItem(USER_OBJECT, JSON.stringify(user));
};
/**
 * @description remove shop id form localStorage
 */
export const destroyUserObject = (): void => {
    window.localStorage.removeItem(USER_OBJECT);
};
/**
 * @description remove shop id form localStorage
 */
export const clearLocalStorage = (): void => {
    window.localStorage.clear();
};

/**
 * @description save shop preferences into localStorage
 */
export const saveShopPreferences = (preferences: Shop): void => {
    window.localStorage.setItem(SHOP_PREFERENCES, JSON.stringify(preferences));
};

/**
 * @description get shop id form localStorage
 */
export const getShopPreferences = () => {
    return window.localStorage.getItem(SHOP_PREFERENCES);
};
/**
 * @description remove shop id form localStorage
 */
export const destroyShopPreferences = (): void => {
    window.localStorage.removeItem(SHOP_PREFERENCES);
};

export const getAllShopIds = () => {
    return JSON.parse(String(window.localStorage.getItem(ID_SHOPs)));
};

/**
 * @description get user object form localStorage
 */
export const getUserObject = () => {
    return JSON.parse(String(window.localStorage.getItem(USER_OBJECT)));
};

export const saveAllShopIds = (ids): void => {
    window.localStorage.setItem(ID_SHOPs, JSON.stringify(ids));
};

export default {
    saveShopId,
    getShopID,
    destroyShopID,
    saveUserObject,
    clearLocalStorage,
    saveShopPreferences,
    getShopPreferences,
    destroyShopPreferences,
    saveAllShopIds,
    getAllShopIds,
    destroyUserObject,
    getUserObject,
    destroyShopIDs,
};
