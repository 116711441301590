import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { Mutations, Actions } from '@/store/enums/StoreEnums';
import JwtService from '@/core/services/JwtService';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/dashboard',
        component: () =>
            import('@/layouts/bluewinds/main-layout/MainLayout.vue'),
        meta: {
            middleware: 'auth',
        },
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () =>
                    import('@/views/bluewinds/dashboards/Dashboard.vue'),
                meta: {
                    pageTitle: 'Dashboard',
                    breadcrumbs: ['Dashboards'],
                },
            },
            {
                path: '/select-shop',
                name: 'select-shop',
                component: () =>
                    import('@/views/bluewinds/dashboards/PreDashboard.vue'),
                meta: {
                    pageTitle: 'Select Shop',
                    breadcrumbs: ['Select Shop'],
                },
            },
        ],
    },
    {
        path: '/menus',
        redirect: '/menus',
        component: () =>
            import('@/layouts/bluewinds/main-layout/MainLayout.vue'),
        meta: {
            middleware: 'auth',
        },
        children: [
            {
                path: '/',
                redirect: '/menus',
                children: [
                    {
                        path: '/menus/overview',
                        name: 'menus-overview',
                        component: () =>
                            import('@/views/bluewinds/menus/Overview.vue'),
                        meta: {
                            pageTitle: 'Overview',
                            breadcrumbs: ['Menus', 'Overview'],
                        },
                    },
                    {
                        path: '/menus/all-menus',
                        name: 'all-menus',
                        component: () =>
                            import('@/views/bluewinds/menus/Menus.vue'),
                        meta: {
                            pageTitle: 'All Menus',
                            breadcrumbs: ['Menus'],
                        },
                    },
                    {
                        path: '/menus/menu-details',
                        name: 'menu-details',
                        component: () =>
                            import('@/views/bluewinds/menus/MenuDetails.vue'),
                        meta: {
                            pageTitle: 'Menu Details',
                            breadcrumbs: ['Menus', 'Menu Details'],
                        },
                    },
                    {
                        path: '/menus/all-categories',
                        name: 'all-categories',
                        component: () =>
                            import('@/views/bluewinds/menus/Categories.vue'),
                        meta: {
                            pageTitle: 'All Categories',
                            breadcrumbs: ['Menus', 'Categories'],
                        },
                    },
                    {
                        path: '/menus/category-details',
                        name: 'category-details',
                        component: () =>
                            import(
                                '@/views/bluewinds/menus/CategoryDetails.vue'
                            ),
                        meta: {
                            pageTitle: 'Category Details',
                            breadcrumbs: ['Menus', 'Category Details'],
                        },
                    },
                    {
                        path: '/menus/all-combos',
                        name: 'all-combos',
                        component: () =>
                            import('@/views/bluewinds/menus/Combos.vue'),
                        meta: {
                            pageTitle: 'All Combos',
                            breadcrumbs: ['Menus', 'Combos'],
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/shop-users',
        redirect: '/shop-users',
        component: () =>
            import('@/layouts/bluewinds/main-layout/MainLayout.vue'),
        meta: {
            middleware: 'auth',
        },
        children: [
            {
                path: '/shop-users',
                name: 'shop-users',
                component: () =>
                    import('@/views/bluewinds/ShopUsers/ShopUsers.vue'),
                meta: {
                    pageTitle: 'Shop Users',
                    breadcrumbs: ['ShopUsers'],
                },
            },
        ],
    },
    {
        path: '/shop-preferences',
        redirect: '/shop-preferences',
        component: () =>
            import('@/layouts/bluewinds/main-layout/MainLayout.vue'),
        meta: {
            middleware: 'auth',
        },
        children: [
            {
                path: '/shop-preferences/business-status',
                name: 'business-status',
                component: () =>
                    import(
                        '@/views/bluewinds/shop-preferences/BusinessStatus.vue'
                    ),
                meta: {
                    pageTitle: 'Business Status',
                    breadcrumbs: ['Shop-preferences'],
                },
            },
            {
                path: '/shop-preferences/sync-with-meals4u-site',
                name: 'sync-with-meals4u-site',
                component: () =>
                    import(
                        '@/views/bluewinds/shop-preferences/SyncWithMeals4u.vue'
                    ),
                meta: {
                    pageTitle: 'Sync With Meals4U Site',
                    breadcrumbs: ['Shop-preferences'],
                },
            },
            {
                path: '/shop-preferences/opening-hours',
                name: 'opening-hours',
                component: () =>
                    import(
                        '@/views/bluewinds/shop-preferences/OpeningHours.vue'
                    ),
                meta: {
                    pageTitle: 'Opening Hours',
                    breadcrumbs: ['Shop-preferences'],
                },
            },
            {
                path: '/shop-preferences/holiday-opening-hours',
                name: 'holiday-opening-hours',
                component: () =>
                    import(
                        '@/views/bluewinds/shop-preferences/HolidayOpeningHours.vue'
                    ),
                meta: {
                    pageTitle: 'Holiday Opening Hours',
                    breadcrumbs: ['Shop-preferences'],
                },
            },
            {
                path: '/shop-preferences/logo-and-other-settings',
                name: 'logo-and-other-settings',
                component: () =>
                    import(
                        '@/views/bluewinds/shop-preferences/LogoAndOtherSettings.vue'
                    ),
                meta: {
                    pageTitle: 'Shop details',
                    breadcrumbs: ['Shop-preferences'],
                },
            },
            {
                path: '/shop-preferences/special-charges',
                name: 'special-charges',
                component: () =>
                    import(
                        '@/views/bluewinds/shop-preferences/SpecialCharges.vue'
                    ),
                meta: {
                    pageTitle: 'Special Charges',
                    breadcrumbs: ['Shop-preferences'],
                },
            },
            {
                path: '/shop-preferences/overview',
                name: 'overview',
                component: () =>
                    import(
                        '@/views/bluewinds/shop-preferences/delivery-collection-settings/Overview.vue'
                    ),
                meta: {
                    pageTitle: 'Delivery/Pickup Overview',
                    breadcrumbs: ['Shop-preferences'],
                },
            },
            {
                path: '/shop-preferences/delivery_settings',
                name: 'Delivery Settings',
                component: () =>
                    import(
                        '@/views/bluewinds/shop-preferences/delivery-collection-settings/DeliverySettings.vue'
                    ),
                meta: {
                    pageTitle: 'Delivery Settings',
                    breadcrumbs: ['Shop-preferences'],
                },
            },
            {
                path: '/shop-preferences/collection_settings',
                name: 'Collection Settings',
                component: () =>
                    import(
                        '@/views/bluewinds/shop-preferences/delivery-collection-settings/CollectionSettings.vue'
                    ),
                meta: {
                    pageTitle: 'Collection Settings',
                    breadcrumbs: ['Shop-preferences'],
                },
            },
        ],
    },
    {
        path: '/offers-and-discount',
        redirect: '/offers-and-discount',
        component: () =>
            import('@/layouts/bluewinds/main-layout/MainLayout.vue'),
        meta: {
            middleware: 'auth',
        },
        children: [
            {
                path: '/',
                redirect: '/offers-and-discount',
                children: [
                    {
                        path: '/offers-and-discount/create-new-offer',
                        name: 'create-new-offer',
                        component: () =>
                            import(
                                '@/views/bluewinds/offers-and-discount/CreateNewOffer.vue'
                            ),
                        meta: {
                            pageTitle: 'Create new offer',
                            breadcrumbs: [
                                'Offers And Discount',
                                'Create New Offer',
                            ],
                        },
                    },
                    {
                        path: '/offers-and-discount/existing-offers',
                        name: 'existing-offers',
                        component: () =>
                            import(
                                '@/views/bluewinds/offers-and-discount/ExistingOffers.vue'
                            ),
                        meta: {
                            pageTitle: 'Existing offers',
                            breadcrumbs: [
                                'Offers And Discount',
                                'Existing Offers',
                            ],
                        },
                    },
                    {
                        path: '/offers-and-discount/edit-offer',
                        name: 'edit-offer',
                        component: () =>
                            import(
                                '@/views/bluewinds/offers-and-discount/EditOffer.vue'
                            ),
                        meta: {
                            pageTitle: 'Edit offer',
                            breadcrumbs: ['Offers And Discount', 'Edit Offer'],
                        },
                    },
                    {
                        path: '/offers-and-discount/offer-analytics',
                        name: 'offer-analytics',
                        component: () =>
                            import(
                                '@/views/bluewinds/offers-and-discount/OfferAnalytics.vue'
                            ),
                        meta: {
                            pageTitle: 'Offer Analytics',
                            breadcrumbs: [
                                'Offers And Discount',
                                'Offer Analytics',
                            ],
                        },
                    },
                ],
            },
        ],
    },

    {
        path: '/orders',
        redirect: '/orders',
        component: () =>
            import('@/layouts/bluewinds/main-layout/MainLayout.vue'),
        meta: {
            middleware: 'auth',
        },
        children: [
            {
                path: '/',
                redirect: '/orders',
                children: [
                    {
                        path: '/orders/view-orders',
                        name: 'view-orders',
                        component: () =>
                            import('@/views/bluewinds/orders/ViewOrders.vue'),
                        meta: {
                            pageTitle: 'View Orders',
                            breadcrumbs: ['New Orders', 'New Orders'],
                        },
                    },
                    {
                        path: '/orders/history-of-orders',
                        name: 'history-of-orders',
                        component: () =>
                            import(
                                '@/views/bluewinds/orders/HistoryOfOrders.vue'
                            ),
                        meta: {
                            pageTitle: 'History of Orders',
                            breadcrumbs: [
                                'History of Orders',
                                'History of Orders',
                            ],
                        },
                    },
                    {
                        path: '/orders/summary',
                        name: 'summary',
                        component: () =>
                            import('@/views/bluewinds/orders/Summary.vue'),
                        meta: {
                            pageTitle: 'Summary',
                            breadcrumbs: ['Summary'],
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/invoices',
        redirect: '/invoices',
        component: () =>
            import('@/layouts/bluewinds/main-layout/MainLayout.vue'),
        meta: {
            middleware: 'auth',
        },
        children: [
            {
                path: '/',
                redirect: '/invoices',
                children: [
                    {
                        path: '/invoices/monthly-invoices',
                        name: 'monthly-invoices',
                        component: () =>
                            import(
                                '@/views/bluewinds/invoices/MonthlyInvoices.vue'
                            ),
                        meta: {
                            pageTitle: 'Monthly Invoices',
                            breadcrumbs: ['Monthly Invoices'],
                        },
                    },
                ],
            },
            {
                path: '/',
                redirect: '/invoices',
                children: [
                    {
                        path: '/invoices/weekly-invoices',
                        name: 'weekly-invoices',
                        component: () =>
                            import(
                                '@/views/bluewinds/invoices/ViewWeeklyInvoices.vue'
                            ),
                        meta: {
                            pageTitle: 'Weekly Invoices',
                            breadcrumbs: ['Weekly Invoices'],
                        },
                    },
                ],
            },
        ],
    },

    {
        path: '/',
        component: () => import('@/layouts/AuthLayout.vue'),
        children: [
            {
                path: '/sign-in',
                name: 'sign-in',
                component: () =>
                    import('@/views/bluewinds/authentication/SignIn.vue'),
                meta: {
                    pageTitle: 'Sign In',
                },
            },
            {
                path: '/password-reset',
                name: 'password-reset',
                component: () =>
                    import(
                        '@/views/bluewinds/authentication/PasswordReset.vue'
                    ),
                meta: {
                    pageTitle: 'Password reset',
                },
            },
            {
                path: '/new-password',
                name: 'new-password',
                component: () =>
                    import('@/views/bluewinds/authentication/NewPassword.vue'),
                meta: {
                    pageTitle: 'New password',
                },
            },
        ],
    },
    {
        path: '/',
        component: () => import('@/layouts/SystemLayout.vue'),
        children: [
            {
                // the 404 route, when none of the above matches
                path: '/404',
                name: '404',
                component: () =>
                    import('@/views/crafted/authentication/Error404.vue'),
                meta: {
                    pageTitle: 'Error 404',
                },
            },
            {
                path: '/500',
                name: '500',
                component: () =>
                    import('@/views/crafted/authentication/Error500.vue'),
                meta: {
                    pageTitle: 'Error 500',
                },
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404',
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // current page view title
    document.title = `${to.meta.pageTitle}`;

    // reset config to initial state
    store.commit(Mutations.RESET_LAYOUT_CONFIG);

    // verify auth token before each page change
    store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

    // before page access check if page requires authentication
    if (to.meta.middleware == 'auth') {
        if (store.getters.isUserAuthenticated) {
            next();
        } else {
            next({ name: 'sign-in' });
        }
    } else {
        next();
    }

    // Scroll page to top on every route change
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
});

export default router;
