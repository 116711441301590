import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import ApiService from '@/core/services/ApiService';
import {
    ShopUserData,
    Shop,
} from '@/store/modules/data_interfaces/ShopUserData';
@Module
export default class ShopUsersModule extends VuexModule {
    errors = {};
    shop_users: Array<ShopUserData> = [];
    shops: Array<Shop> = [];
    get getUserShopErrors() {
        return this.errors;
    }
    get getShopsUsers() {
        //get users of a shop
        return this.shop_users;
    }
    get getShopErrors() {
        return this.errors;
    }
    get getUsersShops() {
        //get shops of a user
        return this.shops;
    }
    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_SHOP_USERS](payload) {
        this.errors = {};
        this.shop_users = payload;
    }
    @Mutation
    [Mutations.SET_USER_SHOPS](payload) {
        this.errors = {};
        this.shops = payload.shops;
    }
    // Get User Shops
    @Action
    [Actions.GET_USER_SHOPS]() {
        ApiService.setHeader();
        return ApiService.post('/shop/auth/get_shops_of_a_user', {})
            .then(({ data }) => {
                this.context.commit(Mutations.SET_USER_SHOPS, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    if (response.data.error)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.error,
                        ]);
                    if (response.data.message)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.message,
                        ]);
                }
            });
    }

    // Get Shop Users
    @Action
    [Actions.GET_SHOP_USERS](payload) {
        ApiService.setHeader();
        return ApiService.post('/shop/auth/get_shop_users', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_SHOP_USERS, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    if (response.data.error)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.error,
                        ]);
                    if (response.data.message)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.message,
                        ]);
                }
            });
    }

    // Add Shop Users
    @Action
    [Actions.Add_SHOP_USERS](payload) {
        ApiService.setHeader();
        return ApiService.post('/shop/register_shop_user', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_SHOP_USERS, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    if (response.data.error)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.error,
                        ]);
                    if (response.data.message)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.message,
                        ]);
                }
            });
    }

    // Delete Shop User
    @Action
    [Actions.DELETE_SHOP_USER](payload) {
        ApiService.setHeader();

        return ApiService.post('/shop/auth/delete_user', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_SHOP_USERS, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    if (response.data.error)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.error,
                        ]);
                    if (response.data.message)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.message,
                        ]);
                }
            });
    }

    //Edit User
    @Action
    [Actions.EDIT_SHOP_USER](payload) {
        ApiService.setHeader();

        return ApiService.post('/shop/auth/edit_user', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_SHOP_USERS, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    if (response.data.error)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.error,
                        ]);
                    if (response.data.message)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.message,
                        ]);
                }
            });
    }
    //Edit User Role
    @Action
    [Actions.EDIT_SHOP_USER_ROLE](payload) {
        ApiService.setHeader();

        return ApiService.post('/shop/auth/edit_user_role', payload)
            .then(() => {
                this.context.commit(Mutations.SET_SHOP_USERS, {});
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    if (response.data.error)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.error,
                        ]);
                    if (response.data.message)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.message,
                        ]);
                }
            });
    }

    //Send reset link to user to reset Password
    @Action
    [Actions.SEND_RESET_PASSWORD_LINK](payload) {
        ApiService.setHeader();

        return ApiService.post('/shop/auth/send_reset_link', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_SHOP_USERS, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    if (response.data.error)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.error,
                        ]);
                    if (response.data.message)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.message,
                        ]);
                }
            });
    }
    //Update logged in user's password
    @Action
    [Actions.UPDATE_USER_PASSWORD](payload) {
        ApiService.setHeader();

        return ApiService.post('/shop/auth/update_password', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_SHOP_USERS, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    if (response.data.error)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.error,
                        ]);
                    if (response.data.message)
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.message,
                        ]);
                }
            });
    }
}
