import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import ApiService from '@/core/services/ApiService';

@Module
export default class ComboModule extends VuexModule {
    errors = {};
    combos: Array<any> = [];

    get getComboErrors() {
        return this.errors;
    }

    get getCombo() {
        return this.combos;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_COMBOS](payload) {
        this.errors = {};
        this.combos = payload.combos;
    }

    // Get Combos of a shop
    @Action
    [Actions.GET_COMBOS](payload) {
        ApiService.setHeader();

        return ApiService.post('/combo/all_combos', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_COMBOS, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }

    // Add new Combo
    @Action
    [Actions.ADD_COMBO](payload) {
        ApiService.setHeader();

        return ApiService.post('/combo/add_combo', payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }

    // Edit a Combo
    @Action
    [Actions.EDIT_COMBO](payload) {
        ApiService.setHeader();

        return ApiService.post('/combo/edit_combo', payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.error ||
                            response.data.errors ||
                            response.data.message,
                    ]);
                }
            });
    }

    // Delete a combo
    @Action
    [Actions.DELETE_COMBO](payload) {
        ApiService.setHeader();

        return ApiService.post('/combo/delete_combo', payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    if (response.data.message && response.data.menu_ids) {
                        this.context.commit(Mutations.SET_ERROR, [
                            {
                                message: response.data.message,
                                menu_ids: response.data.menu_ids,
                            },
                        ]);
                    } else {
                        this.context.commit(Mutations.SET_ERROR, [
                            response.data.error ||
                                response.data.errors ||
                                response.data.message,
                        ]);
                    }
                }
            });
    }
}
