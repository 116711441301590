import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import ApiService from '@/core/services/ApiService';

@Module
export default class PossibleAllergiesModule extends VuexModule {
    errors = {};
    // TODO: Sample array, will be empty when possibleAllergies will be present in mongodb
    possibleAllergies: Array<any> = [
        {
            _id: 1,
            name: 'A',
        },
        {
            _id: 2,
            name: 'B',
        },
        {
            _id: 3,
            name: 'C',
        },
    ];

    get getAllPossibleAllergies() {
        return this.possibleAllergies;
    }

    get getPossibleAllergiesErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_POSSIBLE_ALLERGIES](payload) {
        this.errors = {};
        this.possibleAllergies = payload.possibleAllergies;
    }

    // TODO: Uncomment when possibleAllergies route available

    // Get all tags of a shop
    @Action
    [Actions.GET_POSSIBLE_ALLERGIES](payload) {
        ApiService.setHeader();

        // return ApiService.post("/possible_allergies/all_possible_allergies", payload)
        //   .then(({ data }) => {
        //     this.context.commit(Mutations.SET_POSSIBLE_ALLERGIES, data);
        //   })
        //   .catch(({ response }) => {
        //     if (!response) {
        //       this.context.commit(Mutations.SET_ERROR, ["Network Error"]);
        //     } else {
        //       this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        //     }
        //   });
    }
}
