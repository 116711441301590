import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import ApiService from '@/core/services/ApiService';

@Module
export default class UploadFileModule extends VuexModule {
    errors = {};
    file: object = {};

    get getUploadFileErrors() {
        return this.errors;
    }

    get getFile() {
        return this.file;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_FILE](payload) {
        this.errors = {};
        this.file = payload;
    }

    // Upload a file
    @Action
    [Actions.UPLOAD_FILE](payload) {
        return ApiService.post('/upload', payload)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_FILE, data);
            })
            .catch(({ response }) => {
                if (!response) {
                    this.context.commit(Mutations.SET_ERROR, ['Network Error']);
                } else {
                    this.context.commit(Mutations.SET_ERROR, [
                        response.data.message,
                    ]);
                }
            });
    }
}
